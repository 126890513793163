<template>
  <div>
    <slot name="ProgressBar"/>
    <page-header
      :title="`시작 시간을 알려주세요.`"
    >
    </page-header>

    <div class="ui-border-line ui-h-0 ui-mb-1"></div>

    <div class="ui-border-line" style="background-color: #e4e4e4"></div>

    <div class="ui-two-box">
      <check-box
        :text="hourText ? hourText : '시간'"
        :click="() => {
          modalHourText = hourText
          $refs.HourModal.show()
        }"
        :on="Boolean(hourText)"
        select
      >
      </check-box>
      <check-box
        :text="minuteText ? minuteText : '분'"
        :click="() => {
          modalMinuteText = minuteText
          $refs.MinuteModal.show()
        }"
        :on="Boolean(minuteText)"
        select
      >
      </check-box>
    </div>

    <div class="ui-border-line" style="background-color: #e4e4e4"></div>

    <modal
      ref="HourModal"
      title="시간을 선택해주세요."
      :confirmCb="modal => hourModalConfirm(modal)"
      :hiddenCb="() => {}"
    >
      <ul slot="body">
        <li
          v-for="(row, key) in hourArr" :key="key"
          @click="() => onSelect('h', row)"
          class="text-center"
          :class="{ on: modalHourText === row }"
        >
          {{row}}
          <!-- <icon class="icon" :src="`img/common/check${modalHourText === row ? '-on' : ''}.png`" width="2rem" height="6rem" :cover="false" /> -->
          <div class="ui-border-line"></div>
        </li>
      </ul>
    </modal>

    <modal
      ref="MinuteModal"
      title="분을 선택해주세요."
      :confirmCb="modal => {
        minuteText = modalMinuteText
        modal.hide()
      }"
      :hiddenCb="() => {}"
    >
      <ul slot="body">
        <li
          class="text-center"
          v-for="(row, key) in minuteArr" :key="key"
          @click="() => onSelect('m', row)"
          :class="{ on: modalMinuteText === row }"
        >
          {{row}}
          <!-- <icon class="icon" :src="`img/common/check${modalMinuteText === row ? '-on' : ''}.png`" width="2rem" height="6rem" :cover="false" /> -->
          <div class="ui-border-line"></div>
        </li>
      </ul>
    </modal>

    <footer-box
      submitText="다음"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import Icon from '@/components/common/Icon'
import CheckBox from '@/components/common/CheckBox'
import Modal from '@/components/common/Modal'

export default {
  components: {
    PageHeader,
    FooterBox,
    Icon,
    CheckBox,
    Modal,
  },
  data() {
    return {
      eventTime: null,
      hourText: null,
      modalHourText: null,
      minuteText: null,
      modalMinuteText: null,
      hourArr: [ '오전 0시', '오전 1시', '오전 2시', '오전 3시', '오전 4시', '오전 5시', '오전 6시', '오전 7시', '오전 8시', '오전 9시', '오전 10시', '오전 11시', '오후 12시', '오후 1시', '오후 2시', '오후 3시', '오후 4시', '오후 5시', '오후 6시', '오후 7시', '오후 8시', '오후 9시', '오후 10시', '오후 11시' ],
      minuteArr: [ '00분', '10분', '20분', '30분', '40분', '50분' ]
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate: {
      get() {
        return this.hourText && this.minuteText
      },
    },
  },
  methods: {
    nextStep() {
      const ymd = this.moment(sessionStorage.getItem('eventDate')).format('YYYY-MM-DD')
      const m = this.minuteText.replace(/\D/, '')
      const hMatch = this.hourText.match(/^(오[전후]) (\d+)시$/)
      let h = ''

      if (hMatch[1] === '오전' || hMatch[2] === '12') {
        h = hMatch[2]
      } else {
        h = `${Number(hMatch[2]) + 12}`
      }

      let date = moment(`${ymd} ${h.length === 1 ? '0' + h : h}:${m}:00`)

      const playDate = date.unix().valueOf()

      this.postOffer = { playDate }
      this.$parent.nextStep()
    },
    hourModalConfirm(modal) {
      this.hourText = this.modalHourText
      modal.hide()
      setTimeout(() => {
        this.$refs?.MinuteModal?.show?.()
      }, 200)
    },
    onSelect(time, val) {
      switch (time) {
        case 'h':
          this.modalHourText = val
          break
        case 'm':
          this.modalMinuteText = val
          break
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/constant.scss';

.ui-two-box {
  overflow: hidden;
  margin: 0 -3rem;
  > div {
    float: left;
    width: 50%;
    &:first-child {
      padding-left: 3rem;
      padding-right: 1.5rem;
      border-right: 1px solid #ececec;
    }
    &:last-child {
      padding-left: 1.5rem;
      padding-right: 3rem;
    }
  }
}
</style>
