var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("ProgressBar"),
      _c("page-header", { attrs: { title: "시작 시간을 알려주세요." } }),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-1" }),
      _c("div", {
        staticClass: "ui-border-line",
        staticStyle: { "background-color": "#e4e4e4" }
      }),
      _c(
        "div",
        { staticClass: "ui-two-box" },
        [
          _c("check-box", {
            attrs: {
              text: _vm.hourText ? _vm.hourText : "시간",
              click: function() {
                _vm.modalHourText = _vm.hourText
                _vm.$refs.HourModal.show()
              },
              on: Boolean(_vm.hourText),
              select: ""
            }
          }),
          _c("check-box", {
            attrs: {
              text: _vm.minuteText ? _vm.minuteText : "분",
              click: function() {
                _vm.modalMinuteText = _vm.minuteText
                _vm.$refs.MinuteModal.show()
              },
              on: Boolean(_vm.minuteText),
              select: ""
            }
          })
        ],
        1
      ),
      _c("div", {
        staticClass: "ui-border-line",
        staticStyle: { "background-color": "#e4e4e4" }
      }),
      _c(
        "modal",
        {
          ref: "HourModal",
          attrs: {
            title: "시간을 선택해주세요.",
            confirmCb: function(modal) {
              return _vm.hourModalConfirm(modal)
            },
            hiddenCb: function() {}
          }
        },
        [
          _c(
            "ul",
            { attrs: { slot: "body" }, slot: "body" },
            _vm._l(_vm.hourArr, function(row, key) {
              return _c(
                "li",
                {
                  key: key,
                  staticClass: "text-center",
                  class: { on: _vm.modalHourText === row },
                  on: {
                    click: function() {
                      return _vm.onSelect("h", row)
                    }
                  }
                },
                [
                  _vm._v(" " + _vm._s(row) + " "),
                  _c("div", { staticClass: "ui-border-line" })
                ]
              )
            }),
            0
          )
        ]
      ),
      _c(
        "modal",
        {
          ref: "MinuteModal",
          attrs: {
            title: "분을 선택해주세요.",
            confirmCb: function(modal) {
              _vm.minuteText = _vm.modalMinuteText
              modal.hide()
            },
            hiddenCb: function() {}
          }
        },
        [
          _c(
            "ul",
            { attrs: { slot: "body" }, slot: "body" },
            _vm._l(_vm.minuteArr, function(row, key) {
              return _c(
                "li",
                {
                  key: key,
                  staticClass: "text-center",
                  class: { on: _vm.modalMinuteText === row },
                  on: {
                    click: function() {
                      return _vm.onSelect("m", row)
                    }
                  }
                },
                [
                  _vm._v(" " + _vm._s(row) + " "),
                  _c("div", { staticClass: "ui-border-line" })
                ]
              )
            }),
            0
          )
        ]
      ),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }